<template>
  <div>
    <carousal-content :contents="contents" page="home"/>
    <!-- ==== feature section start ==== -->
    <section class="feature section__space feature__one__alt">
      <div class="container">
        <div class="feature__area">
          <div class="row">
            <div class="col-xl-3">
              <div
                class="
                  feature__thumb
                  wow
                  animate__animated animate__slideInLeft
                "
              >
                <img
                  src="@/assets/images/feature/illustration.png"
                  alt="Illustration"
                />
              </div>
            </div>
            <div class="col-xl-9">
              <div class="feature__item">
                <div class="row d-flex align-items-center">
                  <div class="col-md-6 col-lg-4">
                    <div
                      class="
                        feature__item-content
                        d-flex
                        align-items-center
                        column__space
                        wow
                        animate__animated animate__fadeInUp
                      "
                    >
                      <img
                        src="@/assets/images/feature/feature-one.png"
                        alt="Feature"
                      />
                      <p>
                        Simple to use platform to ship any kinds of product.
                        Heavy or light.
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div
                      class="
                        feature__item-content
                        d-flex
                        align-items-center
                        column__space
                        wow
                        animate__animated animate__fadeInUp
                      "
                      data-wow-delay="0.2s"
                    >
                      <img
                        src="@/assets/images/feature/feature-two.png"
                        alt="Feature"
                      />
                      <p>Competitive shipping rates</p>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <div
                      class="
                        feature__item-content
                        d-flex
                        align-items-center
                        wow
                        animate__animated animate__fadeInUp
                      "
                      data-wow-delay="0.3s"
                    >
                      <img
                        src="@/assets/images/feature/feature-three.png"
                        alt="Feature"
                      />
                      <p>Refer to a friend and get a discount</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="feature__animated__img">
        <img
          src="@/assets/images/feature/ellipse.png"
          alt="Ellipse"
          class="ellipse"
        />
        <img
          src="@/assets/images/feature/ellipse-small.png"
          alt="Ellipse"
          class="small-ellipse"
        />
        <img
          src="@/assets/images/feature/ellipse.png"
          alt="Ellipse"
          class="ellipse-two"
        />
        <img
          src="@/assets/images/feature/ellipse-small.png"
          alt="Ellipse"
          class="small-ellipse-two"
        />
      </div>
    </section>
    <!-- ==== #feature section end ==== -->

    <!-- ==== work section start ==== -->
    <section class="work-two section__space work__two__alt">
      <div class="container">
        <div class="section__header section__header__space">
          <hr />
          <h2 class="content__space">How does ShipIndia works</h2>
          <p class="secondary">
            Instant Quote: a simple, painless solution for shipping your boxes
            and small personal items to over 200 countries
          </p>
        </div>
        <div class="work__area-two">
          <div class="row">
            <div class="col-md-6 col-lg-4 col-xxl-3 alt__space">
              <div
                class="
                  work__content-two
                  wow
                  animate__animated animate__fadeInUp
                "
              >
                <img
                  src="@/assets/images/icons/login.png"
                  alt="Login"
                  class="cta__space"
                />
                <h5 class="content__space">Log In</h5>
                <p>
                  Register with us and receive a unique address in india to ship
                  your product to. Its our warehouse
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xxl-3 alt__space">
              <div
                class="
                  work__content-two
                  wow
                  animate__animated animate__fadeInUp
                "
                data-wow-delay="0.1s"
              >
                <img
                  src="@/assets/images/icons/shopping.png"
                  alt="shopping"
                  class="cta__space"
                />
                <h5 class="content__space">Shopping</h5>
                <p>
                  Phosfluorescently transition adaptive synergy through
                  prospective best practices. Progressively e-enable customer
                </p>
              </div>
            </div>
            <div
              class="
                col-md-6 col-lg-4 col-xxl-3
                alt__space
                work__content--third
              "
            >
              <div
                class="
                  work__content-two
                  wow
                  animate__animated animate__fadeInUp
                "
                data-wow-delay="0.2s"
              >
                <img
                  src="@/assets/images/icons/notification.png"
                  alt="notification"
                  class="cta__space"
                />
                <h5 class="content__space">Get Notification</h5>
                <p>
                  Once we have received the item, we will notify you and you
                  will view a picture of it. You can advise us if you wish to
                  ...
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xxl-3">
              <div
                class="
                  work__content-two
                  wow
                  animate__animated animate__fadeInUp
                "
                data-wow-delay="0.3s"
              >
                <img
                  src="@/assets/images/icons/payment.png"
                  alt="payment"
                  class="cta__space"
                />
                <h5 class="content__space">Payment & Delivery</h5>
                <p>
                  Once you have confirmed and make the payment. We will ship
                  your product an strive to ...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ==== #work section end ==== -->

    <!-- ==== sign up section start ==== -->
    <section class="sign__up">
      <div class="container">
        <div class="sign__up__area">
          <div class="row d-flex align-items-center">
            <div class="col-lg-8">
              <div class="sign__up__content column__space">
                <h2
                  class="primary wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  Sign up now to enjoy <br />
                  10% off your first shipment!
                </h2>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="sign__up__cta text-start text-lg-end">
                <a href="https://app.shipindia.co/register" class="button button--primary button--secondary"
                  >Sign Up Now</a
                >
              </div>
            </div>
          </div>
          <img
            src="@/assets/images/sign-up/plane.png"
            alt="Flying Plane"
            class="flying-plane"
          />
          <img src="@/assets/images/sign-up/leaf.png" alt="Leaf" class="leaf" />
        </div>
      </div>
    </section>
    <!-- ==== #sign up section end ==== -->
    <!-- ==== why use section start ==== -->
    <section
      class="use section__space bg__img use__one__alt"
    >
      <div class="container">
        <div class="use__area">
          <div class="section__header section__header__space">
            <hr />
            <h2 class="content__space wow animate__animated animate__fadeInUp">
              Why Use ShipIndia
            </h2>
            <p
              class="secondary wow animate__animated animate__fadeInUp"
              data-wow-delay="0.2s"
            >
              Instant Quote: a simple, painless solution for shipping your boxes
              and small personal items to over 200 countries
            </p>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-4 col-xxl-3 alt__space">
              <div class="use__features">
                <img
                  src="@/assets/images/use/discount.png"
                  alt="discount"
                  class="cta__space"
                />
                <h6
                  class="content__space wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.1s"
                >
                  Bundling Discount
                </h6>
                <p
                  class="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Dont have to worry about how to ship your products or enquire.
                  We will handle it for you till door
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xxl-3 alt__space">
              <div class="use__features">
                <img
                  src="@/assets/images/use/delivery.png"
                  alt="delivery"
                  class="cta__space"
                />
                <h6
                  class="content__space wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.1s"
                >
                  Multiple Delivery Options
                </h6>
                <p
                  class="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Dont have to worry about how to ship your products or enquire.
                  We will handle it for you till door
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xxl-3 alt__space">
              <div class="use__features use__features--third">
                <img
                  src="@/assets/images/use/shipping.png"
                  alt="shipping"
                  class="cta__space"
                />
                <h6
                  class="content__space wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.1s"
                >
                  Additional Shipping Protection
                </h6>
                <p
                  class="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Dont have to worry about how to ship your products or enquire.
                  We will handle it for you till door
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xxl-3 alt__space">
              <div class="use__features use__features--last">
                <img
                  src="@/assets/images/use/cost.png"
                  alt="cost"
                  class="cta__space"
                />
                <h6
                  class="content__space wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.1s"
                >
                  No hidden costs
                </h6>
                <p
                  class="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Dont have to worry about how to ship your products or enquire.
                  We will handle it for you till door
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src="@/assets/images/use/ellipse.png"
        alt="ellipse"
        class="ellipse"
      />
    </section>
    <!-- ==== #why use section end ==== -->

    <!-- ==== features brand start ==== -->
    <section class="brand section__space brand__one__alt">
      <div class="container">
        <div class="brand__area">
          <div class="row d-flex align-items-center">
            <div class="col-lg-4">
              <div class="brand__title column__space">
                <div class="section__header">
                  <hr />
                  <h4>Featured Brands</h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div
                class="
                  brand__logo
                  d-flex
                  align-items-center
                  justify-content-start justify-content-lg-around
                  column__space
                "
              >
                <img src="@/assets/images/brands/amazon.png" alt="Amazon" />
                <img src="@/assets/images/brands/rakuten.png" alt="Rakuten" />
              </div>
            </div>
            <div class="col-lg-4">
              <div
                class="
                  brand__cta
                  d-flex
                  justify-content-start justify-content-lg-end
                "
              >
                <a href="#" class="primary"
                  >View All Brands <i class="fas fa-chevron-right"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="brand__animation">
        <img
          src="@/assets/images/brands/orange-ellipse.png"
          alt="Animated Circle"
          class="orange"
        />
        <img
          src="@/assets/images/brands/green-ellipse.png"
          alt="Animated Circle"
          class="green"
        />
      </div>
    </section>
    <!-- ==== #features brand end ==== -->
  </div>
</template>

<script>
import { WOW } from "wowjs";
import CarousalContent from '../components/CarousalContent.vue';
export default {
  name: "Home",
  components: {
    CarousalContent
  },
  mounted() {
    new WOW().init();
  },
  data() {
    return {
      contents: [
        {
          heading: "Shop in India, Ship to Singapore",
          text: "No more excess baggage cost. No more wondering how to get products thats based in india only.",
        },
        {
          heading: "Shop in India, Ship to Singapore",
          text: "No more excess baggage cost. No more wondering how to get products thats based in india only.",
        },
        {
          heading: "Shop in India, Ship to Singapore",
          text: "No more excess baggage cost. No more wondering how to get products thats based in india only.",
        }

      ],
    };
  }
};
</script>

<style>

</style>
